<route>
{
  "meta": {
    "permission": [
      "sales.view_order"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <v-toolbar dark color="secondary">
        <v-toolbar-title
          v-if="$vuetify.breakpoint.smAndUp"
          class="font-weight-black"
          >{{ $tc('driver', 0) }}
        </v-toolbar-title>
      </v-toolbar>
      <assignsuborder
        v-if="drivers.length > 0"
        :items="drivers"
        mode="driver"
      />
    </v-card>
    <v-col
      align="center"
      justify="center"
      class="display-1 font-weight-black grey--text"
      cols="12"
      v-show="drivers.length === 0"
    >
      {{ $vuetify.lang.t('$vuetify.noDataText') }}
      <br />
    </v-col>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import assignsuborder from '../../components/sale/assignItem.vue'

export default {
  components: {
    assignsuborder
  },
  data() {
    return {
      drivers: []
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  watch: {
    drivers(val) {
      if (val.length > 0) {
        this.showDrivers = true
      }
    }
  },
  methods: {
    async getDrivers() {
      let assigned = await this.$api.sale.drivers({})
      this.drivers = assigned.data.results
    }
  },
  mounted() {
    this.getDrivers()
  }
}
</script>
